import {log_event} from "../log_event/log_event";
import utils from "./utils";
import {rejectError} from "../handler/fetchCommon";

const NEED_REQUEST_BODY_METHOD = ["post", "put", "patch"];
const DEFAULT_HEADERS = {
    "Content-Type": "application/x-www-form-urlencoded"
};

export default class Fetch {
    static _fixParams(request) {
        let reqData = "";
        let reqUrl = "";
        const {
            url,
            method,
            headers,
            params,
            data,
            encode
        } = request;
        if (NEED_REQUEST_BODY_METHOD.includes(method.toLowerCase())) {
            switch (headers["Content-Type"]) {
                case "application/x-www-form-urlencoded":
                    for (const key of Object.keys(data)) {
                        if (encode === true) {
                            reqData += `&${key}=${encodeURIComponent(data[key])}`;
                        } else {
                            reqData += `&${key}=${data[key]}`;
                        }
                    }
                    reqData = reqData.substring(1);
                    break;
                case "application/json":
                    reqData = JSON.stringify(data);
                    break;
                default:
                    reqData = data;
            }
            reqUrl = url;
        } else {
            for (const key of Object.keys(params)) {
                if (encode === true) {
                    reqUrl += `&${key}=${encodeURIComponent(params[key])}`;
                } else {
                    reqUrl += `&${key}=${params[key]}`;
                }
            }
            reqUrl = `${url}?${reqUrl.substring(1)}`;
            reqData = null;
        }
        reqUrl = this._fixPath(reqUrl);
        return {
            reqData,
            reqUrl
        }
    }

    static _fixPath(path) {
        if (path.startsWith("/")) {
            return path;
        } else {
            return "/" + path;
        }
    }

    static _fixResposneHeaders(rawHeader) {
        const arr = rawHeader.trim().split(/[\r\n]+/);
        const headerMap = {};
        arr.forEach((line) => {
            const parts = line.split(': ');
            const header = parts.shift();
            const value = parts.join(': ');
            headerMap[header] = value;
        });
        return headerMap;
    }

    static decodeResult(word) {
        const AES = require("crypto-js/aes");
        const encUtf8 = require("crypto-js/enc-utf8");
        const encHex = require("crypto-js/enc-hex");
        const encBase64 = require("crypto-js/enc-base64");
        const padPkcs7 = require("crypto-js/pad-pkcs7");

        const key = encUtf8.parse('9vApxLk5G3PAsJrM');
        const iv = encUtf8.parse('FnJL7EDzjqWjcaY9');
        const encryptedHexStr = encHex.parse(word);
        const srcs = encBase64.stringify(encryptedHexStr);
        const result = AES.decrypt(srcs, key, {
            iv,
            // mode: CryptoJS.mode.CBC,
            padding: padPkcs7
        });
        const decryptedStr = result.toString(encUtf8);
        return JSON.parse(decryptedStr);
    }

    /**
     * 发出请求，会返回一个promise
     * then返回一个对象，包含data、headers、statusCode、statusText和config
     * catch返回一个对象，同时打印或抛出错误，可以通过status来判断哪种错误
     *      status - 0 非200错误，http请求过程成功
     *      status - 1 请求超时
     *      status - 2 网络错误
     *
     * @param {*} param
     */
    static request({
                       method = "get",
                       baseURL,
                       url,
                       params,
                       data,
                       encode=false,
                       decode,
                       responseType = "json",
                       headers = DEFAULT_HEADERS,
                       timeout = 3000
                   } = {}) {
        try {
            return new Promise((resolve, reject) => {
                const request = { method, baseURL, url, params, data, responseType, headers, timeout, encode };
                console.log("request", request);
                const { reqUrl, reqData } = this._fixParams(request);
                const http = new XMLHttpRequest();
                // load
                http.addEventListener("load", (e) => {
                    console.log("http", http);
                    if (http.status === 200) {
                        const isObject = Object.prototype.toString.call(http.response) === "[object Object]";
                        resolve({
                            data: (decode && !isObject) ? this.decodeResult(http.response) : http.response,
                            // data: http.response,
                            headers: this._fixResposneHeaders(http.getAllResponseHeaders()),
                            statusCode: http.status,
                            statusText: http.statusText,
                            config: request
                        });
                    } else {
                        reject({
                            status: 0,
                            errorMessage: "server error",
                            errorCode: http.status,
                            errorText: http.statusText,
                            XMLHttpRequest: e.target
                        });
                    }
                })
                // timeout
                http.addEventListener("timeout", (e) => {
                    console.warn(`request timeout over ${request.timeout}`);
                    reject({
                        status: 1,
                        errorMessage: "request timeout",
                        XMLHttpRequest: e.target
                    })
                })
                // error
                http.addEventListener("error", (e) => {
                    reject({
                        status: 2,
                        errorMessage: "network error",
                        XMLHttpRequest: e.target
                    })
                })

                const openUrl = baseURL ? `${baseURL}${reqUrl}` : reqUrl;
                http.open(method.toLowerCase(), openUrl, true);
                http.timeout = timeout;
                if (NEED_REQUEST_BODY_METHOD.includes(method.toLowerCase())) {
                    http.setRequestHeader("Content-Type", headers["Content-Type"]);
                }
                for (const key of Object.keys(headers)) {
                    if (key !== "Content-Type") {
                        http.setRequestHeader(key, headers[key]);
                    }
                }
                http.responseType = responseType;
                http.send(reqData);
            })
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    }

    // 封装请求处理错误
    static req(params) {
        return new Promise((resolve, reject) => {
            this.request(params).then(({ data }) => {
                if (data && data.meta && (data.meta.code === 200 || data.meta.code === "200") && data.data) {
                    resolve(data.data)
                } else {
                    const error = data && data.meta;
                    error.fromServer = true
                    reject(error);
                }
            }).catch(({ errorCode, errorMessage, status }) => {
                reject({ errorCode, errorMessage, status });
            })
        })
    }
}

import serviceUtils from "../util";
import {rejectError} from "../fetchCommon";
import utils from "common/utils";
import {
    getCommonParams,
    appendCheckoutButtonAndDesc,
    redirectAfterApprove
} from "./checkoutCommonMethod";
import Fetch from "common/fetch";
import config from "common/config";
import urlUtils from "common/urlUtils";

const createOrder = (urlData, url, stripe_client_id, isSub) => {
    return new Promise((resolve, reject) => {
        const path = "/web/v3/stripe/order/create";
        const post_data = getCommonParams(urlData, stripe_client_id, true);
        post_data.quantity = 1;
        post_data.mode = isSub ? "subscription" : "payment";
        post_data.success_url = location.origin + `?fk=stripe_success_page&session_id={CHECKOUT_SESSION_ID}`;
        post_data.cancel_url = location.origin + "?fk=stripe_cancel_page";
        urlData.email && (post_data.customer_email = urlData.email);
        //请求服务器:
        Fetch.req({
            method: "POST",
            baseURL: url ? url : config.baseUrl, //后台分配的request_url,在/init的时候返回的
            url: path,
            data: post_data,
            encode: true,
            decode: true,
            timeout: 10000,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((data) => {
                console.log("createOrder data", data);
                resolve(data.id);
            })
            .catch((error) => {
                rejectError(error, path);
                reject(error);
            })
            .finally(() => {
                utils.$animation.hide();
            });
    });
};

let retryTimes = 0;
const approveOrder = (urlData, url, session_id, stripe_client_id) => {
    utils.$animation.finishProcessingAni();
    return new Promise((resolve, reject) => {
        const path = "/web/v3/stripe/order/approve";
        const post_data = getCommonParams(urlData, stripe_client_id, true);
        post_data.transaction_id = session_id;
        //请求服务器:
        Fetch.req({
            method: "POST",
            baseURL: url ? url : config.baseUrl, //后台分配的request_url,在/init的时候返回的
            url: path,
            data: post_data,
            encode: true,
            timeout: 15000,
            decode: true,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((data) => {
                resolve(data);
            })
            .catch(async (error) => {
                // 如果是网络问题并且没有retry过
                if (error && !error.fromServer && !retryTimes) {
                    retryTimes++;
                    try {
                        const result = await approveOrder(
                            urlData
                        );
                        resolve(result);
                    } catch (e) {
                        reject(error);
                    }
                } else {
                    rejectError(error, path);
                    reject(error);
                }
            });
    });
};

const confirmOrder = async (urlData) => {
    try {
        const cacheUrlData = JSON.parse(sessionStorage.getItem("cacheUrlData"));
        const request_url = sessionStorage.getItem("cacheRequestUrl");
        const stripe_client_id = sessionStorage.getItem("cacheStripeClientId");
        const data = await approveOrder(cacheUrlData, request_url, urlData.session_id, stripe_client_id);
        redirectAfterApprove(data, cacheUrlData);
        sessionStorage.clear();
    } catch (e) {
        sessionStorage.clear();
    }
}

const cancelOrder = () => {
    const cacheUrlData = JSON.parse(sessionStorage.getItem("cacheUrlData"));
    window.location.href = `${window.location.origin}?${urlUtils.stringify(
        cacheUrlData, true
    )}`
    sessionStorage.clear();
}

const appendCardCheckoutButton = (urlData, stripe_client_id, request_url) => {
    const src = "https://js.stripe.com/v3/";
    serviceUtils.loadJs(src, () => {
        appendCheckoutButtonAndDesc(urlData, true);
        const checkoutButton = document.getElementById("checkout-button");
        utils.$animation.hide();
        checkoutButton.addEventListener("click", async function () {
            const isSubs = utils.isSubsOffer(urlData);
            const stripe = Stripe(stripe_client_id);
            const result = await createOrder(
                urlData,
                request_url,
                stripe_client_id,
                isSubs
            );
            console.log("createOrder result", result);
            // 缓存urlData
            sessionStorage.setItem("cacheUrlData", JSON.stringify(urlData));
            sessionStorage.setItem("cacheRequestUrl", request_url);
            sessionStorage.setItem("cacheStripeClientId", stripe_client_id);
            stripe.redirectToCheckout({ sessionId: result });
        })
    });
};

export { appendCardCheckoutButton, approveOrder, confirmOrder, cancelOrder };

import { rejectError } from "./fetchCommon";
import Fetch from "common/fetch";
import serviceUtils from "./util";

const handler = (urlData, config, isCard) => {
  return new Promise((resolve, reject) => {
    const master_id = serviceUtils.getAttribute(urlData, "master_id");
    const platform = serviceUtils.getAttribute(urlData, "platform");
    const offer_id = serviceUtils.getAttribute(urlData, "offer_id");
    const path = isCard ? "/web/v3/stripe/order/init" : "/web/v3/order/init";
    //请求服务器:
    Fetch.req({
      method: "GET",
      baseURL: config.baseUrl,
      url: path,
      params: {
        master_id,
        platform,
        offer_id
      },
      encode: true,
      decode: true,
      timeout: 10000
    }).then((data) => {
      console.log("init result", data);
      resolve(data)
    })
        .catch((error) => {
          // reject(errorMessage);
          rejectError(error, path)
          reject(error);// 抛出错误，如果产生了错误，让外层catch走button模式
        })
  })
}
export default handler;

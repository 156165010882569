import Fetch from "common/fetch";
import utils from "common/utils";
import urlUtils from "common/urlUtils";
import { rejectError } from "./fetchCommon";
import { log_event } from "../log_event/log_event";

// 当urldata中信息为paypal的逻辑
const handler = (urlData, config) => {
  let post_data = {
    offer_id: urlData.offer_id,
    user_info: urlData.user_info,
    offer_platform: urlData.platform,
    primary_platform: urlData.platform,
    single_pay: true,
    mver: urlData.hasOwnProperty("mver") ? urlData.mver : null,
    source_scheme: urlData.source_scheme,
    js_bundle_version: "1.5.0"
  };
  // 如果feed_data字段存在，则传给接口，否则不传这个字段
  if (urlData.hasOwnProperty("feed_data")) {
    post_data.feed_data = urlData.feed_data;
  }

  //判断是否有重定向地址：[纯web会传这个字段过来]
  if (urlData.redirect_url) {
    post_data.redirect_url = urlData.redirect_url;
  } else {
    //没有重定向的地址：通过本域名加写死路由，由本代码域名处理，加#是因为非history模式
    post_data.redirect_url = window.location.origin + "/#/successPay/";
  }

  //请求服务器:
  Fetch.request({
    method: "POST",
    baseURL: config.baseUrl,
    url: "/web/v1/order/create",
    data: post_data,
    encode: true,
    timeout: 10000,
    decode: true
  })
    .then(({ data }) => {
      console.log("success data:", data);
      // postpaypal之后需要立马重定向到data中的url地址，并且传data数据给querystring
      const queryData = {
        shouldPostForm: data,
        pay: "paypal",
        rawData: {}
      };
      const { paypal_url, result, err } = data;
      if (result === "succ") {
        if (paypal_url) {
          window.location.href = `${paypal_url}?${urlUtils.stringify(
            queryData
          )}`;
        } else {
          utils.$toast.error("redirectUrlOrigin is missing");
        }
      } else if (err) {
        utils.$toast.warn(err);
        log_event("[error]:/mobile/post_feed_data", err);
      }
    })
    .catch(({ errorCode, errorMessage, status }) => {
      rejectError({ errorCode, errorMessage, status }, "/mobile/post_feed_data")
    })
    .finally(() => {
      utils.$animation.hide();
    });
};

export default handler;

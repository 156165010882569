// 公共函数
const utils = {
  isObject(obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  },
  // 获取feedData里面的数据
  getAttribute(data, attribute, required) {
    let result = this.getAttributeFromData(data, attribute);
    // 如果是必须参数并且返回为undefined或者Null, 则返回空串
    if (required && !result) {
      result = "";
    }
    return result;
  },
  /*
  * 获取target_user_id
  * 先从feed_data里面取，再取user_info的master_id，最后取user_info的user_id
  * */
  getTargetUserId(urlData) {
    const feed_data = urlData.feed_data && JSON.parse(urlData.feed_data);
    if (feed_data && feed_data.hasOwnProperty("user_id") && feed_data.user_id) {
      return feed_data.user_id;
    } else if (this.getMasterId(urlData)) {
      return this.getMasterId(urlData)
    } else {
      const user_info = urlData.user_info && JSON.parse(urlData.user_info);
      return user_info && user_info.user_id
    }
  },
  // 获取masterId
  getMasterId(urlData) {
    return this.getAttribute(urlData, "master_id", true)
  },
  getAttributeFromData(data, attribute) {
    try {
      if (data.hasOwnProperty(attribute)) {
        if (attribute === "user_info" || attribute === "feed_data") {
          return JSON.parse(data[attribute]);
        } else {
          return data[attribute];
        }
      } else {
        const user_info = data.user_info && JSON.parse(data.user_info);
        if (user_info.hasOwnProperty(attribute)) {
          return user_info[attribute]
        }
        const feed_data = data.feed_data && JSON.parse(data.feed_data);
        if (feed_data.hasOwnProperty(attribute)) {
          return feed_data[attribute];
        }
        return null;
      }
    } catch(e) {
      return null
    }
  },
  loadJs(url, callback) {
    const script = document.createElement('script'),
      fn = callback || function(){};
    script.type = 'text/javascript';
    //IE
    if (script.readyState){
      script.onreadystatechange = function(){
        if( script.readyState == 'loaded' || script.readyState == 'complete' ){
          script.onreadystatechange = null;
          fn();
        }
      };
    } else {
      //其他浏览器
      script.onload = function(){
        fn();
      };
    }
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  },
  getPurePrice(price) {
    try {
      if (!price) {
        return "";
      }
      let priceString;
      if (this.isNumber(price)) {
        priceString = price.toString();
      } else {
        priceString = price;
      }
      return this.parsePrice(priceString);
    } catch (e) {
      return this.isNumber(price) ? price.toString() : price.slice(1).trim();
    }
  },
  isNumber(val) {
    const regPos = /^\d+(\.\d+)?$/; // 非负浮点数
    const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  parsePrice(string) {
    const value = string.match(/\d+(?:\.\d+)?/g);
    if (value) {
      return value[0];
    }
    return "";
  },
  formatTime(number) {
    const month_en  = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul" ,"Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(number);
    const month = month_en[date.getMonth()];
    const day = this.formatNumber(date.getDate());
    const year = date.getFullYear();
    const hour = date.getHours() > 11 ? date.getHours() - 12 : date.getHours();
    const minute = this.formatNumber(date.getMinutes());
    const zone = date.getHours() > 11 ? "PM" : "AM";
    return `${month} ${day}, ${year} at ${hour}:${minute} ${zone}`;
  },
  formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
  },
  calcSubsCount(data, product_count, type) {
    const {
      subscription_month_period,
      subscription_week_period,
      subscription_day_period
    } = data;
    const month = subscription_month_period && parseInt(subscription_month_period);
    const week = subscription_week_period && parseInt(subscription_week_period);
    const day = parseInt(subscription_day_period);
    const typeName = type === "like" ? "New Post" : "Day";
    if (month && month >= 12) {
      const year = month / 12;
      return `${product_count} / ${typeName} (${year} ${year > 1 ? "years" : "year"})`
    } else if (month && month < 12) {
      return `${product_count} / ${typeName} (${month} ${month > 1 ? "months" : "month"})`
    } else if (week) {
      return`${product_count} / ${typeName} (${week} ${week > 1 ? "weeks" : "week"})`
    } else if (day) {
      return`${product_count} / ${typeName} (${day} ${day > 1 ? "days" : "day"})`
    }
  },
  formatToThousands(num) {
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  },
  getServiceEffect(result) {
    let actual_effect;
    if (result.actual_effect) {
      actual_effect = result.actual_effect;
    } else {
      const offer_data = result.offer_data;
      const kind = offer_data && offer_data.kind;
      const paypal_offer =
          offer_data &&
          offer_data.pay_data &&
          offer_data.pay_data.paypal;
      if (kind && kind.includes("lucky_coin")) {
        // lucky coins的显示数量
        const lucky_effect = offer_data && offer_data.lucky_effect;
        actual_effect =
            lucky_effect.min +
            "~" +
            this.formatToThousands(lucky_effect.max);
      } else {
        actual_effect = paypal_offer && paypal_offer.effect;
      }
    }
    return actual_effect;
  }
}

export default utils;

// index.html需要插入的script,所有html需要插入的script都必须与html同名，如果想让页面引入css，必须通过模块导入
// 如果想实现热替换(不刷新整个页面，局部更新)，在下方加上 module.hot.accept()即可

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept()
}

import "../css/index.scss";
import $ from "common/dom";
// import "common/fontAdjust";
import urlUtils from "common/urlUtils";
import utils from "common/utils";
import Handler from "./handler/index";
import { confirmOrder, cancelOrder } from "./handler/checkout/cardCheckout";
/**
 * 初始化：转化URl>Data,如果为card支付显示card,如果为paypal则postForm
 * @constructor
 */
class Initial {
  constructor() {
    this.urlData = null;
    this.fullUrl = window.location.href;
    this._init();
  }

  _process() {
    const isRedirectSelfToPostForm = this.urlData.hasOwnProperty("shouldPostForm");
    const isFromWebPaySite = this.urlData.hasOwnProperty("fromWebPaySite");
    // gif动画: 支付成功，支付过程中
    this.fullUrl.indexOf("/successPay") > -1 && !isRedirectSelfToPostForm
      ? utils.$animation.successfulAni()
      : utils.$animation.processingAni();

    // 如果url中有shouldPostForm的query参数，则表明是页面自己重定向来的
    // 需要取出shouldPostForm参数，直接form表单submit
    if (isRedirectSelfToPostForm) {
      console.log("should post form redirectly");
      const data = JSON.parse(this.urlData.shouldPostForm);
      const rawData = JSON.parse(this.urlData.rawData);
      const payBy = this.urlData.pay;
      if (payBy === "paypal") {
        utils.postPayPalForm(data);
      } else if (payBy === "fasterpay") {
        utils.postFasterPayForm(rawData, data);
      } else {
        utils.$toast.error("redirect invalidly");
      }
    } else if (isFromWebPaySite) {
      utils.handleProcessForWebSite(this.urlData);
    } else if (
        this.urlData.hasOwnProperty("stripe_client_id") ||
        (
            !this.urlData.hasOwnProperty("clientId") &&
            this.urlData.hasOwnProperty("payby") &&
            this.urlData.payby === "card"
        )
    ) {
      // ================= card pay ============
      Handler.cardSwitch(this.urlData);
    } else if (
        this.urlData.hasOwnProperty("from_kind") &&
        this.urlData.from_kind === "stripe_success_page"
    ) {
      //========card购买成功跳转回来，需要手动执行approve接口 ==========
      confirmOrder(this.urlData);
    } else if (
        this.urlData.hasOwnProperty("from_kind") &&
        this.urlData.from_kind === "stripe_cancel_page"
    ) {
      // === card取消页面
      cancelOrder();
    } else if (
      this.fullUrl.indexOf("/successPay") === -1
      && this.urlData.hasOwnProperty("offer_id")
    ) {
      //==================== paypal pay =======================
      Handler.switch(this.urlData);
    } else if (this.fullUrl.indexOf("/successPay") > -1) {
      //====================== 购买成功跳转回来 ========================
      Handler.buyBack();
    } else {
      //============= 直接同过域名去访问的，需要加入iframe进行显示 =========
      Handler.directView();
    }
  }

  _init() {
    //将url转换为Object
    this.urlData = urlUtils.getUrlData(window.location.href);
    console.log("this.urlData", this.urlData);

    // 如果url中product_kind的value含有like，则先展示提示页面
    // if (
    //   this.urlData.hasOwnProperty("product_kind")
    //   && this.urlData.product_kind.includes("like")
    // ) {
    //   $("#important-notice").css({ display: "block" });
    //   const btn = document.querySelector(".toNextBtn");
    //   btn.addEventListener("click", (e) => {
    //     $("#important-notice").css({ display: "none" });
    //     this._process();
    //   });
    // } else {
      this._process();
    // }
  }
}
new Initial();
